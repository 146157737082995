import React, { Fragment } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Time from "../images/time_management.svg"
import Order from "../images/order_ride.svg"
import Rate from "../images/reviews.svg"
import Fade from "react-reveal/Fade"
import { Row, Col, Container, Button, Card } from "react-bootstrap"

const WhyUs = ({ siteTitle }) => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  const lang = intl.locale === "en" ? "US" : "ID"

  return (
    <Fragment>
      <Row>
        <Col className={"text-center mt-5 mb-4"}>
          <Fade bottom delay={400}>
            <h1 style={{ fontWeight: "bold" }}>
              {intl.formatMessage({ id: "why-order" })}
            </h1>
          </Fade>
        </Col>
      </Row>
      <Row className={"mb-5 "}>
        <Col xs={12} md={4} style={{ borderRadius: "200" }}>
          <Fade bottom delay={200}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className={"m-3"}>
                    <img src={Rate} alt="Avatar" className={"height-10 mt-3"} />
                    <p className={"mb-3"}>
                      {intl.formatMessage({ id: "card-title-1" })}
                    </p>
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className={"m-3 content-back"}>
                    <p>{intl.formatMessage({ id: "card-desc-1" })}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Col>
        <Col xs={12} md={4}>
          <Fade bottom delay={400}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className={"m-3"}>
                    <img
                      src={Order}
                      alt="Avatar"
                      className={"height-10 mt-3"}
                    />
                    <p className={"mb-3"}>
                      {intl.formatMessage({ id: "card-title-2" })}
                    </p>
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className={"m-3 content-back"}>
                    <p>{intl.formatMessage({ id: "card-desc-2" })}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Col>
        <Col xs={12} md={4}>
          <Fade bottom delay={600}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className={"m-3"}>
                    <img src={Time} alt="Avatar" className={"height-10 mt-3"} />
                    <p className={"mb-3"}>
                      {intl.formatMessage({ id: "card-title-3" })}
                    </p>
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className={"m-3 content-back"}>
                    <p>{intl.formatMessage({ id: "card-desc-3" })}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Col>
      </Row>
    </Fragment>
  )
}

WhyUs.propTypes = {}

WhyUs.defaultProps = {
  siteTitle: ``,
}

export default WhyUs
