import { useIntl } from "gatsby-plugin-intl"
import Banner from "../images/mockup_banner-pc.png"
import React, { Fragment } from "react"
import { Row, Col, Button } from "react-bootstrap"

const BannerSection2 = ({ siteTitle }) => {
  const intl = useIntl()

  return (
    <Fragment>
      <Row className={"mb-5 py-5 px-2"} style={{ backgroundColor: "#F9F9F9" }}>
        <Col lg={4}>
          <h1>{intl.formatMessage({ id: "wanna-company" })}</h1>
          <p>{intl.formatMessage({ id: "wanna-company-desc" })}</p>
          <a
            href="https://drive.google.com/file/d/1otyH_qNje64QYtp517kc4VfSecPvvpoR/view?usp=sharing"
            target="_blank"
          >
            <Button
              className={
                "btn-primary btn-shadow-primary rounded-pill mt-4 px-4"
              }
              style={{ fontWeight: "bold" }}
            >
              {intl.formatMessage({ id: "learn-more" })}
            </Button>
          </a>
        </Col>
        <div className={"d-block d-sm-none p-3"}></div>
        <Col lg={8}>
          <img alt={"passenger"} src={Banner} className={"height-100"} />
        </Col>
      </Row>
    </Fragment>
  )
}

BannerSection2.propTypes = {}

BannerSection2.defaultProps = {
  siteTitle: ``,
}

export default BannerSection2
