import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import MockImg from "../images/mockup-login.png"
import SEO from "../components/seo"
import Driver from "../images/join-driver.jpg"
import Passenger from "../images/join-user.jpg"
import Fade from "react-reveal/Fade"
import { Row, Col, Container, Button, Card } from "react-bootstrap"
import BannerSection from "../components/banner"
import BannerSection2 from "../components/bannerSection2"
import BannerDriver from "../components/bannerDriver"
import WhyUs from "../components/whyUs"

const IndexPage = () => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""

  return (
    <div>
      <SEO title={"Pesan Tiket Bis Online Anti Ribet"} />
      <Layout>
        <div className="row mt-5">
          <Container>
            <BannerSection />
            <br />
            <br />
            <WhyUs />
            <br />
            <Row className={"mb-5 mt-5"}>
              <Col md={6} className={"text-center"}>
                <Fade bottom delay={400}>
                  <img alt={"Ngebis"} src={MockImg} className={"height-20"} />
                </Fade>
              </Col>
              <Col md={6} className={"text-center"}>
                <Fade bottom delay={400}>
                  <h1 className={"text-left mt-3"}>
                    {intl.formatMessage({ id: "how-to-use" })}
                  </h1>
                </Fade>
                <Row>
                  <Fade bottom delay={500}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>1</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-1" })}</b>
                        <br />
                        <small className={"text-number-circle"}>
                          {intl.formatMessage({ id: "point-desc-1" })}
                        </small>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>
                <Row>
                  <Fade bottom delay={600}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>2</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-2" })}</b>
                        <br />
                        <small className={"text-number-circle"}>
                          {intl.formatMessage({ id: "point-desc-2" })}
                        </small>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>
                <Row>
                  <Fade bottom delay={700}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>3</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-3" })}</b>
                        <br />
                        <small className={"text-number-circle"}>
                          {intl.formatMessage({ id: "point-desc-3" })}{" "}
                        </small>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>

                <Row>
                  <Fade bottom delay={800}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>4</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-4" })}</b>
                        <br />
                        <small className={"text-number-circle"}>
                          {intl.formatMessage({ id: "point-desc-4" })}{" "}
                        </small>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>
                <Row>
                  <Fade bottom delay={900}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>5</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-5" })}</b>
                        <br />
                        <small className={"text-number-circle"}>
                          {intl.formatMessage({ id: "point-desc-5" })}{" "}
                        </small>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>
                <Row>
                  <Fade bottom delay={1000}>
                    <Card className={"text-left full-width no-border"}>
                      <Card.Header className={"no-border bg-white"}>
                        <span className={"number-circle"}>6</span>{" "}
                        <b>{intl.formatMessage({ id: "point-title-6" })}</b>
                      </Card.Header>
                    </Card>
                  </Fade>
                </Row>
              </Col>
            </Row>
            <br />
            <BannerSection2 />
            <BannerDriver />
            <Row lg={12} className={"my-5"}>
              <Col className={"text-center mt-5"}>
                <Fade bottom delay={300}>
                  <h1 className={"text-center mt-3"}>
                    {intl.formatMessage({ id: "join-title" })}
                  </h1>
                </Fade>
              </Col>
              <Col lg={12}>
                <div className={"text-center"}>
                  {intl.formatMessage({ id: "join-sub" })}
                </div>
                <br />
                <br />
                <Fade right delay={500}>
                  <Row className={"mb-5"}>
                    <Col md={6} className={"text-left"}>
                      <img
                        alt={"passenger"}
                        src={Passenger}
                        className={"height-80"}
                        style={{ borderRadius: "6%" }}
                      />
                    </Col>
                    <Col md={6} className=" align-self-center">
                      <h2>{intl.formatMessage({ id: "section-1-title" })}</h2>
                      <p>{intl.formatMessage({ id: "section-1-desc" })}</p>
                      <a href={`${locale}/download`}>
                        <Button
                          variant={"success"}
                          className={
                            "btn-primary btn-shadow-primary rounded-pill shadow-lg"
                          }
                        >
                          {intl.formatMessage({ id: "show-more" })}
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Fade>
                <Fade left delay={500}>
                  <Row>
                    <Col md={6} className=" align-self-center">
                      <h2>{intl.formatMessage({ id: "section-2-title" })}</h2>
                      <p>{intl.formatMessage({ id: "section-2-desc" })}</p>
                      <a href="https://forms.gle/2BchCkKH3SFL4ERt5">
                        <Button
                          variant={"success"}
                          className={
                            "btn-primary btn-shadow-primary rounded-pill shadow-lg"
                          }
                        >
                          {intl.formatMessage({ id: "show-more" })}
                        </Button>
                      </a>
                    </Col>
                    <div className={"d-block d-sm-none p-3"}></div>
                    <Col md={6} className={"text-left mb-3"}>
                      <img
                        alt={"driver"}
                        src={Driver}
                        className={"height-80"}
                        style={{ borderRadius: "6%" }}
                      />
                    </Col>
                  </Row>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage
